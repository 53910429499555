import React, { useState } from 'react'
import "./Testimonials.css"
import RA from "../../Asset/RA-removebg-preview.png"
import LA from "../../Asset/LA.png"
import { testimonialsData } from '../Data/testimonialsData'
import {motion} from "framer-motion"
const Testimonials = () => {
  const transition={type:'spring', duration:3};
  const [select,setSelect]=useState(0);
  const tlenght=testimonialsData.length;
  return (
    <div className="Testimonials">
        <div className="left-t">
             <span>Tetimonials </span>
             <span className='stroke-text'>What they</span>
             <span>Hamare bare ma Janaye</span>
       <motion.span
       key={select}
       initial={{opacity:0, x:-100}}
       animate={{opacity: 1, x:0}}
       exit={{opacity:0, x:100}} 
       transition={{transition}}
       >
        {testimonialsData[select].review}
       </motion.span>
       <span>
        <span style={{color:'orange'}}>
          {testimonialsData[select].name}
        </span>{" "}
        - {testimonialsData[select].status}
       </span>
        </div>
        <div className="right-t">
          <motion.div
          initial={{opacity:0, x:-100}}
          transition={{...transition, duration:2}}
          whileInView={{opacity:1, x:0}}
          ></motion.div>
         <motion.div
          initial={{opacity:0, x:100}}
          transition={{...transition, duration:2}}
          whileInView={{opacity:1, x:0}}
          ></motion.div>
<motion.img
key={select}
initial={{opacity:0, x:100}}
animate={{opacity: 1, x:0}}
exit={{opacity:0, x:-100}} 
transition={{transition}}
src={testimonialsData[select].image} alt="" />
       <div className="arrows">
        <img  onClick={()=>(
          select===0
          ?setSelect(tlenght -1 )
          :setSelect((prev)=>prev-1)
        )}
        src={LA} alt="" />
        <img onClick={()=>(
          select=== tlenght - 1
          ?setSelect(0)
          :setSelect((next)=>next+1)
        )}
        src={RA} alt="" />
        </div>
        </div>
    </div>
  )
}

export default Testimonials