import React from 'react'
import "./Plans.css"
import { PlansData } from '../Data/PlansData'
const Plans = () => {
  return (
    <div className="plans-container">
        <div className="programs-header" style={{gap:'2rem'}}>
            <span className='stroke-text'>KiA Ap</span>
            <span>Tyar HA</span>
            <span className='stroke-text'>Hamare Sath</span>
        </div>
        <div className="plans">
{PlansData.map((plan, i)=>(
<div className="plan" key={i}>
    {plan.icon}
    <span>{plan.name}</span>
    <span>${plan.price}</span>
    <div className="feature">
        {plan.features.map((feature)=>(
          <div className="features">
<span>{feature}</span>
          </div>
        ))}
    </div>
</div>
))}
        </div>
    </div>
  )
}

export default Plans
