import React from 'react'
import "./Footer.css"
import insta from "../../Asset/download.jpg"
import fb from "../../Asset/fb.png"
import lnkdn from "../../Asset/lnkdn.png"
import github from "../../Asset/github.png"
import logo from "../../Asset/logo.png"
const Footer = () => {
  return (
    <div className="footer-container">
<hr/>
<div className="footer">
  <div className="social-links">
  <img src={insta} alt="" />
  <img src={fb} alt="" />
  <img src={lnkdn} alt="" />
  <img src={github} alt="" />
  </div>
</div>
<div className="logo-f">
<img src={logo} alt="" />
</div>
    </div>
  )
}

export default Footer
