import React from 'react'
import "./Program.css"
import { programsData } from "../Data/programsData"
const Programs = () => {
  return (
    <div className='Programs' id='programs'>
      <div className="programs-header">
        <span className='stroke-text'>Explore our</span>
        <span>Programs</span>
        <span className='stroke-text'>to shape you </span>
       
      </div>
      <div className="programs-categories">
        {programsData.map((program)=>(
            <div className="category">
                {program.image}
                <span>
                    {program.heading}
                    </span>
                    <span>
{program.details}
<div className="join-now">
    <span>join Now</span>

</div>
                    </span>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Programs
