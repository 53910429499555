import React from 'react'
import Header from '../Header/Header'
import "./Hero.css"
import onee from "../../Asset/onee.png"
import heart from "../../Asset/h.png"
import two from "../../Asset/two.jpg"
import NumberCounter from "number-counter"
import {motion} from "framer-motion"

const Hero = () => {
  const transition ={type: 'spring', duration :3}
  return (
    <div className='hero'>
    
<div className="left-h">
  <Header/>
  <div className="the-best-ad">
    <motion.div
    initial={{left:'135px'}}
    whileInView={{left:'8px'}}
    transition={{...transition, type:'tween'}}
    >
    </motion.div>
    <span>The best fitness in town </span>
  </div>
  <div className="hero-text">
    <div>
    <span className='stroke-text'>Shape </span>
    <span> Your </span>
    </div>
  
  <div><span>Ideal Body </span></div>
  <div><span>
    HEloo Welcome to taha's Work..yaaaa hoooooooooooooooo..work hard and you will get a result in the face of Success
    </span></div>
</div>
<div className="figures">
    <div>
        <span>
 
<NumberCounter end={140} start={100} delay='4' preFix="+" />

        </span>
    <span>Expert Coaches</span>
    </div>
    <div>
          <span><NumberCounter end={120} start={80} delay='4' preFix="+" /></span>
    <span>Members Joined</span>
    </div>
    <div>  
        <span><NumberCounter end={60} start={40} delay='4' preFix="+" /></span>
    <span>Fitness Program</span>
    </div>
</div>
<div className="hero-buttons">
    <buttons className="btn">Get Started</buttons>
    <buttons className="btn">Learn More</buttons>
</div>
</div>
<div className="right-h">
  <button className="btn">Join Now</button>
  <motion.div
  initial={{right:'-1rem'}}
  whileInView={{right:'2rem'}}
  transition={{...transition}}
  className="heart-rate">
  <img src={heart} alt=""  style={{width:"162px", height:"162px"}}/>
  <span>Heart Rate</span>
  <span>116 bpm</span>
</motion.div>
<img src={onee} alt="" className='hero-image' />
<motion.div
 initial={{right:'37rem'}}
 whileInView={{right:'28rem'}}
 transition={transition}
className="calories">
  <img src={two} alt="" />
  <div>
  <span>Calories Burnt</span>
  <span>22 Kcal</span>
  </div>
  
</motion.div>
</div>  
    </div>
  )
}

export default Hero
