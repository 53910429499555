import asif from "../../Asset/asif.jpg"
import afaque from "../../Asset/afaque.jpg"
import taha from "../../Asset/taha.JPG"
export const testimonialsData = [
  {
    image: taha,
    review:
      "Hello its me Muhammad taha Sheikh, Check my work and rate it!",
    name: 'Muhammad Taha',
    status : 'ENTREPRENEUR'
  },
  {
    image: asif,
    review: 'Hello ia ma coach and thanks to taha because he hire me ',
    name: 'Asif',
    status: 'COACH'
  },
  {
    image : afaque,
    review:' Hello i am afaque thanks to them tey give me admission in it',
    name: 'Afaque',
    status: "CUSTOMER"
  }
];
