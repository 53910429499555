import React from 'react'
import "./Reason.css"
import gym1 from "../../Asset/gym1.png"
import gym2 from "../../Asset/gym2.png"
import four from "../../Asset/four.jpg"
import five from "../../Asset/one.png"
const Reason = () => {
  return (
    <div className="Reasons" id='reasons'>
<div className="left-r">
    <img src={gym2} alt="" />
    <img src={four} alt="" />
    <img src={gym1} alt="" />
    <img src={five} alt="" />
</div>
<div className="right-r">
<span>Some Reasons</span>
<div>
    <span className='stroke-text'>
Kio </span>
<span>
 Choose Us?</span> </div>
   
   <div className='details-r'>
   <div>
    <img src="" alt=""/>
   <span>Hellooo </span></div>
   <div><img src="" alt="" />
   <span>HIIIIII</span></div>
   <div><img src="" alt="" />
   <span>Welcome</span></div>
   <div><img src="" alt="" />
   <span>to Taha's Project</span></div>
    </div>
    </div>
    </div>
  )
}

export default Reason
